<template>
  <div>
    <div class="">
      <Breadcrumb />
      <Intro msg="Prenota" />
      <Create />
    </div>
  </div>
</template>


<script>
import Breadcrumb from "@/components/component/Breadcrumb.vue";
import Intro from "@/components/component/Intro.vue";
import Create from "@/components/reservations/Create.vue";

export default {
  name: "Reserve",
  components: {
    Breadcrumb,
    Intro,
    Create,
  },
};
</script>

<style scoped lang="scss">
</style>
