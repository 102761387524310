<template>
  <div class="" v-if="data_obtained">
    <div class="container px-4 mx-auto mb-4">
      <div class="">
        <div>
          <p class="text-lg font-bold text-red">{{ event.data.title }}</p>
          <p class="text-lg">{{ event.data.subtitle }}</p>
        </div>
        <div>
          <p class="inline-block text-lg">
            {{ event.data.day }} {{ event.data.date }}
          </p>
          <span class="inline-block text-lg mx-2">-</span>
          <p class="inline-block text-lg">
            {{ event.data.time.hour }}:{{ event.data.time.minute }}
          </p>
        </div>
        <div>
          <p class="text-lg">{{ event.data.location }}</p>
          <p class="text-lg">{{ event.data.city }}</p>
        </div>
        <div v-if="event.data.video_conference">
          <p class="text-lg">Trasmesso in video conferenza</p>
        </div>
      </div>
    </div>

    <!-- Verifico se ci sono ancora posti disponibili -->
    <div
      v-if="event.data.occupied_seats < event.data.seats"
      class="container px-4 mx-auto"
    >
      <div v-if="!event.data.draft" class="md:w-3/4 xl:w-2/4 mx-auto my-16">
        <form class="" @submit.prevent="validateUser()" novalidate>
          <!-- <md-card class=""> -->
          <md-card-content>
            <div class="md-layout md-gutter">
              <!-- Name -->
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('name')">
                  <label class="block text-red mb-1" for="name">Nome*</label>
                  <md-input
                    class="focus:outline-none"
                    name="name"
                    id="name"
                    v-model="user.name"
                  />
                  <span class="md-error" v-if="!$v.user.name.required"
                    >Questo campo è obbligatorio</span
                  >
                  <span class="md-error" v-else-if="!$v.user.name.minlength"
                    >Questo campo deve contenere almeno 3 caratteri</span
                  >
                </md-field>
              </div>
              <!-- Surname -->
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('surname')">
                  <label class="block text-red mb-1" for="surname"
                    >Cognome*</label
                  >
                  <md-input
                    class="focus:outline-none"
                    name="surname"
                    id="surname"
                    v-model="user.surname"
                  />
                  <span class="md-error" v-if="!$v.user.surname.required"
                    >Questo campo è obbligatorio</span
                  >
                  <span class="md-error" v-else-if="!$v.user.surname.minlength"
                    >Questo campo deve contenere almeno 3 caratteri</span
                  >
                </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <!-- Email -->
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('email')">
                  <label class="block text-red mb-1" for="email">Email*</label>
                  <md-input
                    class="focus:outline-none"
                    type="email"
                    name="email"
                    id="email"
                    v-model="user.email"
                  />
                  <span class="md-error" v-if="!$v.user.email.required"
                    >Questo campo è obbligatorio</span
                  >
                  <span class="md-error" v-else-if="!$v.user.email.email"
                    >Questo campo non è valido</span
                  >
                </md-field>
              </div>
              <!-- Phone -->
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('phone')">
                  <label class="block text-red mb-1" for="phone"
                    >N. telefono*</label
                  >
                  <md-input
                    class="focus:outline-none"
                    type="tel"
                    name="phone"
                    id="phone"
                    v-model="user.phone"
                  />
                  <span class="md-error" v-if="!$v.user.phone.required"
                    >Questo campo è obbligatorio</span
                  >
                  <span class="md-error" v-else-if="!$v.user.phone.minlength"
                    >Questo campo deve contenere almeno 6 numeri</span
                  >
                </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <!-- Cards -->
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('card')">
                  <label class="block text-red mb-1" for="email"
                    >Carte del Circolo*</label
                  >
                  <md-select
                    class="focus:outline-none"
                    name="cards"
                    id="cards"
                    v-model="user.card"
                    md-dense
                  >
                    <md-option
                      v-for="card in cards"
                      v-bind:value="card.value"
                      :key="card.value"
                      class=""
                    >
                      {{ card.text }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!$v.user.card.required"
                    >Questo campo è obbligatorio</span
                  >
                </md-field>
              </div>
              <!-- Age -->
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('age')">
                  <label class="block text-red mb-1" for="age">Età*</label>
                  <md-select
                    class="focus:outline-none"
                    name="age"
                    id="age"
                    v-model="user.age"
                    md-dense
                  >
                    <md-option
                      v-for="a in age"
                      v-bind:value="a.value"
                      :key="a.value"
                      class=""
                    >
                      {{ a.text }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!$v.user.age.required"
                    >Questo campo è obbligatorio</span
                  >
                </md-field>
              </div>
            </div>

            <!-- Message -->
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label class="block text-red mb-1" for="message"
                    >Come sei venuto a conoscenza dell’evento?</label
                  >
                  <md-textarea
                    class="w-full focus:outline-none"
                    id="message"
                    name="message"
                    rows="20"
                    cols="50"
                    maxlength="150"
                    v-model="user.message"
                  ></md-textarea>
                </md-field>
              </div>
            </div>

            <!-- Incipit -->
            <div class="md-layout md-gutter mb-4">
              <div class="md-layout-item md-small-size-100">
                <div class="">
                  <p class="block text-base text-red">
                    Tra questi incipit, quale scegli?
                  </p>
                </div>
                <md-field class="no-after mb-0">
                  <md-radio
                    class="my-1"
                    :value="incipit.first"
                    v-model="user.incipit"
                    >Per molto tempo, mi sono coricato presto la sera.</md-radio
                  >
                </md-field>
                <md-field class="no-after">
                  <md-radio
                    class="my-1"
                    :value="incipit.second"
                    v-model="user.incipit"
                    >Dedicherò la prima conferenza all’opposizione
                    leggerezza-peso, e sosterrò le ragioni della
                    leggerezza.</md-radio
                  >
                </md-field>
              </div>
            </div>

            <!-- Newsletter -->
            <div class="md-layout md-gutter pt-5">
              <div class="md-layout-item md-small-size-100">
                <div class="">
                  <p class="block text-base text-red">Newsletter</p>
                </div>
                <md-field class="no-after mb-0">
                  <md-checkbox
                    class="my-1"
                    id="torino"
                    v-model="user.newsletter.torino"
                    >Voglio iscrivermi alla newsletter di Torino</md-checkbox
                  >
                </md-field>
                <md-field class="no-after mb-0">
                  <md-checkbox
                    class="my-1"
                    id="novara"
                    v-model="user.newsletter.novara"
                    >Voglio iscrivermi alla newsletter di Novara</md-checkbox
                  >
                </md-field>
                <md-field class="no-after">
                  <md-checkbox
                    class="my-1"
                    id="verbania"
                    v-model="user.newsletter.verbania"
                    >Voglio iscrivermi alla newsletter di Verbania</md-checkbox
                  >
                </md-field>
              </div>
            </div>
            <!-- Privacy -->
            <div class="md-layout md-gutter mb-4">
              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="no-after"
                  :class="getValidationClass('privacy')"
                >
                  <!-- <md-checkbox v-model="user.privacy" style="margin: 0"
                    >Ho preso visione e accetto le condizioni descritte nella
                    <a
                      class="text-red"
                      style="font-size: 16px; margin: 0 5px"
                      href="https://www.iubenda.com/privacy-policy/78899767"
                      target="_blank"
                      >privacy policy</a
                    >*
                  </md-checkbox> -->
                  <div
                    class="privacy-checkbox"
                  >
                    <md-checkbox v-model="user.privacy">
                      Ho preso visione e accetto le condizioni descritte nella
                    </md-checkbox>
                    <a
                      class="text-red"
                      href="https://www.iubenda.com/privacy-policy/78899767"
                      target="_blank"
                      >privacy policy *</a
                    >
                  </div>
                  <span class="md-error" v-if="!$v.user.privacy.required"
                    >Questo campo è obbligatorio</span
                  >
                </md-field>
              </div>
            </div>

            <div class="mb-8">
              <p class="">* Campo obbligatorio</p>
            </div>

            <!-- <div class="border my-2">
              {{ user }}
            </div> -->

            <!-- Submit -->
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-button
                  type="submit"
                  class="bg-red text-md text-white uppercase border border-red px-4 py-1 hover:text-red hover:bg-white cursor-pointer transition-colors duration-500 ease-in-out"
                  >Prenota</md-button
                >
              </div>
            </div>
          </md-card-content>
        </form>

        <!-- Message -->
        <!-- <div>
          <div v-if="error_message">
            <div class="mb-6">
              <h4 class="text-red font-bold py-3">
                Si è verificato un errore. Ricarica la pagina e compila
                nuovamente il form.
              </h4>
            </div>
          </div>
        </div> -->
      </div>
      <div v-else>
        <p class="text-xl md:text-2xl text-red text-center my-12">
          Questo evento non è momentaneamente prenotabile!
        </p>
      </div>
    </div>
    <div v-else>
      <p class="text-xl md:text-2xl text-red text-center my-12">
        Non ci sono posti disponibili per questo evento.
      </p>
    </div>
  </div>
</template>


<script>
import firebase from "@/firebase";
import UsersDataService from "@/services/UsersDataService";
import EventsDataService from "@/services/EventsDataService";
// Vuelidate
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "CreateReservation",
  mixins: [validationMixin],
  data() {
    return {
      boolean: false,
      user: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        card: "",
        age: "",
        message: "",
        incipit: "Per molto tempo, mi sono coricato presto la sera.",
        newsletter: {
          torino: false,
          novara: false,
          verbania: false,
        },
        privacy: null,
        // Checked viene poi usato successivamente per la tabella
        checked: false,
      },
      event: {
        id: null,
        data: [],
      },
      event_occupied_seats: null,
      cards: [
        {
          text: "Non posseggo carte del Circolo",
          value: "Non posseggo carte del Circolo",
        },
        { text: "Carta Io leggo", value: "Carta Io leggo" },
        { text: "Carta Io leggo di Più", value: "Carta Io leggo di Più" },
      ],
      age: [
        { text: "Under 18", value: "Under 18" },
        { text: "18-24 anni", value: "18-24 anni" },
        { text: "25-29 anni", value: "25-29 anni" },
        { text: "30-39 anni", value: "30-39 anni" },
        { text: "40-49 anni", value: "40-49 anni" },
        { text: "50-59 anni", value: "50-59 anni" },
        { text: "60-69 anni", value: "60-69 anni" },
        { text: "Over 70", value: "Over 70" },
      ],
      incipit: {
        first: "Per molto tempo, mi sono coricato presto la sera.",
        second:
          "Dedicherò la prima conferenza all’opposizione leggerezza-peso, e sosterrò le ragioni della leggerezza.",
      },
      error_message: false,
      data_obtained: false,
    };
  },
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(3),
      },
      surname: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        minLength: minLength(6),
      },
      card: {
        required,
      },
      age: {
        required,
      },
      privacy: {
        required,
      },
    },
  },
  mounted() {
    // this.event.data.occupied_seats = 0;
    // this.event.data.seats = 1;
    this.readEventFromId();
  },
  methods: {
    readEventFromId() {
      let docID = this.$route.params.id;
      // console.log("docID: ", docID);
      this.event.id = docID;

      EventsDataService.read(docID)
        .then((doc) => {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            this.event.data = doc.data();
            this.data_obtained = true;
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    // Verifico che il singolo campo sia valido
    getValidationClass(fieldName) {
      const field = this.$v.user[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    // Verifico che tutti i campi del form siano validi
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.reserveEvent();
      }
    },
    // Prenoto l'evento
    reserveEvent() {
      // console.log("RESERVE EVENT");

      let new_user = {
        ...this.user,
        events: [this.event.id],
        createdAt: new Date(),
      };

      UsersDataService.create(new_user)
        .then((docRef) => {
          // console.log("Created new item successfully!");
          // console.log("Document written with ID: ", docRef.id);

          // const db = firebase.firestore();
          const householdRef = firebase
            .collection("/events")
            .doc(this.event.id);

          let newUid = docRef.id;
          // console.log("User ID: ", newUid);

          const user_email = new_user.email;
          // console.log("User email: ", user_email);

          return firebase
            .runTransaction((t) => {
              return t.get(householdRef).then((doc) => {
                // console.log("DOC: ", doc);

                // doc doesn't exist; can't update
                if (!doc.exists) return;

                // update the users array after getting it from Firestore.
                let newUserArray = doc.get("users");
                // console.log("newUserArray 1: ", newUserArray);
                newUserArray.push({ id: newUid, email: user_email });
                // console.log("newUserArray 2: ", newUserArray);
                // console.log("LENGTH newUserArray: ", newUserArray.length);
                this.event_occupied_seats = newUserArray.length;
                t.set(householdRef, { users: newUserArray }, { merge: true });
              });
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
        })
        .then(() => {
          // EventsDataService.increaseSeats(
          //   this.event.id,
          //   // this.event.data.occupied_seats
          // )
          //   .then(() => {
          //     // console.log("Increase seats");
          //   })
          //   .catch((error) => {
          //     console.log("Error: ", error);
          //   });
          EventsDataService.setSeats(this.event.id, this.event_occupied_seats)
            .then(() => {
              console.log("Set seats");
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
        })
        .then(() => {
          this.$router.push({ name: "ThankYou" });
        })
        .catch((error) => {
          this.error_message = true;
          console.log("Error: ", error);
        });
    },
  },
};
</script>



<style lang="scss" scoped>
.no-after:after {
  height: 0px;
}
</style>